import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Config from '../Config';
import Analytics from '../Analytics';
import Help from './Help';
import Loader from './Loader'

import logo from '../img/heart-x-globe-pink.svg';


export default function ScreenHome({section, currentSection, login}) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    let { EmailID } = useParams();
    const id = useRef();

    const validate = async () => {
        if(id.current.value) {
            setLoading(true);
            const data = await axios.post('https://us-central1-webbyreport-811f9.cloudfunctions.net/lovies_login', {
                entrantID: id.current.value,
                emailID: EmailID
            });

            setLoading(false);

            if(data.data.user) {
                Analytics.sendLogin(data.data.user.EntrantID);
                login(data.data.user);
            } else {
                setError("Invalid Entrant ID");
                Analytics.sendEvent('Invalid Login');
            }
            //login(id.current.value);
        } else {
            setError("You need to enter your entrant ID");
        }
    }

    const kp = (e) => {
        setError(null)
        if(e.key === 'Enter') {
            validate()
        }
    }

    useEffect(() => Analytics.sendEvent('Recorder Login Page'), []);

    return (
        <>
        <div className="section-home flex flex-force">
            <div className="home-x"></div>
            <div className="home-x home-x-bottom"></div>
            <div className="home-x home-x-bottom-right"></div>
            <div className="section-wide">
                <div className="section">
                    <img src={logo} alt="Heart X Globe" className="home-logo" />
                    <h1>Welcome to<br /><span className="pink plus">Lovies X Everywhere</span></h1>
                    Have Your 7 Words of Lovie Speech? Enter Your Entrant ID to Upload.
                
                    <div className="form">
                        <input ref={id} type="text" placeholder="ENTER ID HERE" onKeyPress={kp}/>
                        <button onClick={validate} className={loading ? " button-disabled" : ""}>I'M READY!</button>

                        <div className="error">{error ? error : <span dangerouslySetInnerHTML={{__html: '&nbsp;'}}></span>}</div>

                        <Help />
                    </div>
                </div>
            </div>
        </div>
        {loading ? <Loader text="Logging in..." /> : null}
        </>
    );
}