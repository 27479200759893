import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import heart from './img/heart-x-globe.svg'
import google from './img/google.svg'

export default function Header({ color="pink", section = 'main'}) {
    const [url, setURL] = useState(null);
    const [text, setText] = useState(null);
    let location = useLocation();

    useEffect(() => {
        if(/*location.pathname === '/' || location.pathname.indexOf('/browse420') !== -1 ||*/ location.pathname.indexOf('/watch/') !== -1 || location.pathname.indexOf('/upload/') !== -1) {
            setURL('/');
            setText({mobile: 'Watch', desktop: 'Watch Speeches'});
        }
    }, [location]);

    return(
        <header className={'header-' + color}>
            <Link to="/" className="logo">
                <img src={heart} alt="Lovies X Everywhere" />
            </Link>
            <div className="header-links">
                {url ? <Link to={url} className="header-links-browse">{window.innerWidth < 768 ? text.mobile : text.desktop}</Link> : null}
                <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <div>
                        PRESENTED BY:
                        <img src={google} alt="Google" />
                    </div>
                </a>
            </div>
            
            
            
        </header>
    );
}