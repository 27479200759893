import React, {useState, useEffect} from 'react';
import { useTransition, animated } from 'react-spring'
import ScreenHome from './ScreenHome';
import ScreenWelcome from './ScreenWelcome';
import ScreenRecorder from './ScreenRecorder';
import ScreenRecorderPre from './ScreenRecorderPre'

import ScreenFinal from './ScreenFinal';

export default function Screens({official}) {
    const pages = [
        ({ style }) => <animated.div style={{ ...style, width: '100%' }}><ScreenHome section={1} currentSection={section} login={login} /></animated.div>,
        ({ style }) => <animated.div style={{ ...style, width: '100%' }}><ScreenWelcome section={2} user={user} currentSection={section} setPiece={setPieceFunc} /></animated.div>,
        ({ style }) => <animated.div style={{ ...style, width: '100%' }}><ScreenRecorderPre section={3} piece={piece} currentSection={section} setShow={setShowFuncNext} official={official} /></animated.div>,
        ({ style }) => <animated.div style={{ ...style, width: '100%' }}><ScreenRecorder section={4} piece={piece} currentSection={section} setShow={setShowFunc} official={official} /></animated.div>,
        ({ style }) => <animated.div style={{ ...style, width: '100%' }}><ScreenFinal section={5} currentSection={section} videoID={videoID} pieceID={piece ? piece.PieceID : null} official={official} /></animated.div>,
    ];

    const [previousSection, setPreviousSection] = useState(0);
    const [section, setSection] = useState(1);
    const [videoID, setVideo] = useState(null);//'0se6BgRraukFLwcq3Sml');
    const [piece, setPiece] = useState(null);//'120574');
    const [user, setUser] = useState(null);

    const transitions = useTransition(section, p => p, {
        from: tab => {
            if(previousSection === 0) {
                return {
                    position: 'absolute',
                    opacity: 0,
                };
            } else {
                return {
                    position: 'absolute',
                    opacity: 0,
                    transform: section <= previousSection ? `translate3d(-100%,0,0)` : `translate3d(100%,0,0)`
                };
            }
            
        },
        enter: { position: 'static', opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: tab => {
            return {
                position: 'absolute',
                opacity: 0,
                transform: section >= previousSection ? `translate3d(-100%,0,0)` : `translate3d(100%,0,0)`
            };
        }
        //{ opacity: 0, transform: 'translate3d(100%,0,0)' },
        //enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        //leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    })

    if (section !== previousSection) setPreviousSection(section);

    const next = (n) => {
        setSection(n+1);
    };

    const login = (u) => {
        setUser(u);
        next(1);
    };

    const setPieceFunc = (p) => {
        setPiece(p);
        next(2);
    };

    const setShowFuncNext = () => {
        setSection(4)
    }

    const setShowFunc = (v) => {
        setVideo(v);
        setSection(5);
    };

    const goHome = () => {
        setSection(2);
        setPiece(null);
        setVideo(null);
    };

    useEffect(() => {
        window.scrollTo(0,0)
    }, [section])

    /*
    {section === 1 ? <ScreenHome section={1} currentSection={section} login={login} /> : null}
                {section === 2 ? <ScreenWelcome section={2} user={user} currentSection={section} setPiece={setPieceFunc} /> : null}
                {section === 3 ? <ScreenRecorder section={3} piece={piece} currentSection={section} setShow={setShowFunc} official={official} /> : null}
                {section === 4 ? <ScreenFinal section={4} currentSection={section} videoID={videoID} pieceID={piece.PieceID} official={official} /> : null}
*/
    return (
        <>
            <div className="content">
                {section > 2 ? <button onClick={goHome} className="home-btn flipped">Back to Winning Work</button> : null}
                
                {transitions.map(({ item, props, key }) => {
                    const Page = pages[item-1]
                    return <Page key={key} style={props} />
                })}
                
            </div>
        </>
    );
}