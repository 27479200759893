import React, {useState, useEffect} from 'react';
import Identify from '../utils/Identify';
//import VideoCapture from './VideoCapture/VideoCapture';
import VideoRecorder from './VideoRecorder';
import VideoRecorderIOS from './VideoRecorderIOS';
import Progress from './Progress';
import Analytics from '../Analytics';
import Help from './Help';

export default function ScreenRecorder({piece = null, section, currentSection, setShow, official}) {
    const identifyInstance = new Identify();
    const ios = identifyInstance.mobileOS() === "iOS" || identifyInstance.mobileOS() === "Android";
    const safari = identifyInstance.safari();
    const [enable, setEnable] = useState('upload');

    const [progress, setProgress] = useState(1);

    const finalize = (videoID) => {
        setShow(videoID);
    };

    useEffect(() => Analytics.sendEvent('Record Page'), []);
    
    return (
        <div className="section-recorder flex">
            <div className="section-wide recorder">
                <div>
                <div className="flex">
                    {enable === null ? 
                        <div>
                            <h1>Recording speech for <div className="strong pink">{piece ? piece.PieceTitle : null}</div></h1>
                            <p><strong>Here's whats going to happen:</strong></p>
                            <p>You're going to record your 5-word speech and we're going to create your very own webby awards show. Then you'll sit back and watch the world's shortest most personalized award show.</p>
                            <p>Finally, you can download a special version of your show so you can share your big moment. Your official speech will appaer on our online gallery so be creative!</p>
                            
                            {ios || safari ? 
                                <button onClick={()=>setEnable('upload')}>Let's Do it</button> 
                                : (
                                    <div className="button-choose">
                                        <button onClick={()=>setEnable('upload')}>Upload a video</button>
                                    </div>
                                )
                            }

                            <Help />
                        </div>
                    : null }
                
                    {enable === 'upload' ? <VideoRecorderIOS piece={piece} setProgress={setProgress} finalize={finalize} official={official} /> : null}
                </div>
            </div>

            
            </div>
            <Progress progress={progress} />
        </div>
    );
}