import React, {useRef} from 'react';
//import Slider from "react-slick";
import BrowseCell from './BrowseCell';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import arrowLeft from '../img/arrow-left.svg';
import arrowRight from '../img/arrow-right.svg';

export default function Rivers({playlists}) {
    const slideShow = useRef();

    return(
        playlists ? 
            playlists.map((list, l) => {
                return (
                    <div key={l} className="playlist-river">
                        
                        <div className="playlist-title">{list.name} Winners</div>
                        
                        {list.videos.length > 0 ? (
                            <CarouselProvider step={window.innerWidth > 768 ? 4 : 2} interval={5000} infinite isPlaying={list.videos.length > 4} visibleSlides={window.innerWidth > 768 ? 4 : 2} totalSlides={list.videos.length} naturalSlideWidth={100} naturalSlideHeight={100} isIntrinsicHeight>
                            <Slider ref={slideShow}>
                                {list.videos.map((video, v) => {
                                    return <Slide key={'cell'+v} className="cell-huh"><BrowseCell key={'cell'+v} data={video} /></Slide>
                                })}    
                            </Slider>
                            {/*<ButtonBack>Back</ButtonBack>
                            <ButtonNext>Next</ButtonNext>*/}
                        </CarouselProvider>
                        ): <div style={{paddingTop: 10, textAlign: 'center'}}>No Videos in playlist yet...</div> }
                        
                    </div>
                );
            })
        : null
    )
}