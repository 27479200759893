import React, {useRef, useState} from 'react';
import Identify from '../utils/Identify';
import Help from './Help';
import Loader from './Loader';
import axios from 'axios';
import Config from '../Config';
import crop from '../img/crop-gif-lovies.gif';

export default function VideoRecorderIOS({piece = null, finalize = null, official=false}) {
    const file = useRef();
    const [uploading, setUploading] = useState(false);
    const identifyInstance = new Identify();
    const isMobile = identifyInstance.mobileOS() === "iOS" || identifyInstance.mobileOS() === "Android";
    const loadFile = async (e) => {
        
        const f = e.target.files[0];
        let fileName = e.target.files[0].name;
        const fileType = e.target.files[0].type;
        const fileSize = e.target.files[0].size;

        if(fileType === "video/webm" || fileType === "video/mov" || fileType === "video/avi" || fileType === "video/mp4" || fileType === "video/quicktime" || fileType === "video/mpeg") {
            if(fileSize >= 20 * 1024 * 1024) {
                alert("Video files must be below 20 MB");
                return;
            }

            let extension = fileName.split('.')
            const ext = extension[extension.length-1];

            setUploading(true);            
            const signedURL = await axios.get(`${Config.endpoint}/signedURL?ext=${ext}&type=${encodeURIComponent(fileType)}`);

            let error = false;
            try {
                await axios.put(signedURL.data.url, f, {
                    headers: {
                        'Content-Type': fileType
                    }
                });
            } catch(err) {
                error = true
                alert("Could not upload video, try again.")
                return
            }

            if(!error) {
                // save to db
                console.log("OFFICIAL:", official)
                let videoID = null
                try {
                    videoID = await axios.post(Config.endpoint+ '/lovies_save', {
                        hash: signedURL.data.id,
                        official: official,
                        pieceID: piece.PieceID,
                        ext: ext
                    })
                } catch(err) {
                    alert("Could not save video to server")
                    return
                }

                setUploading(false);
                finalize(videoID.data.videoID);
                
            }
        } else {
            alert("You must choose a mp4, mov, webm, or avi video file.");
        }
    };

    return (
        <div className="flex">
            <div className="p-top">
                <img src={crop} alt="How to crop" className="crop-gif" />
                <h1>Before You Upload, remember:</h1>
                <ol style={{maxWidth: 500, margin: '0 auto 40px auto', textAlign: 'left'}}>
                    <li>No more than 20MB (about 3 or 4 seconds)</li>
                    <li>Light your face from the front</li>
                    <li>Limit your background noise so we can hear every word.</li>
                    <li>Centre yourself in the frame.</li>
                    <li>Consider your background. No bathrooms please :)</li>
                    <li>Please leave a few seconds before and after you speak.</li>
                    <li>Remember to be yourself!</li>
                </ol>
                <label htmlFor="file" className="inputlabel">Choose official Video</label>
                <input 
                    id="file"
                    className="inputfile"
                    ref={file} 
                    type="file" 
                    accept="video/*;capture=camcorder"
                    onChange={loadFile} />
                {uploading ? <Loader /> : null }

                <Help />
            </div>
        </div>
    );
}