import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/firestore";

import Config from '../Config';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import VideoPlayer from './VideoPlayer';
import ShowVideoPlayer from '../recorder/ShowVideoPlayer';
import Loader from '../recorder/Loader'
import Twitter from '../img/twitter-white.svg';
import Facebook from '../img/facebook-white.svg';
import DownloadImg from '../img/download.svg';

const firebaseConfig = {
    apiKey: "AIzaSyBYWeaP4EOl0gVh59sj-d0BgSnOpJhQaHg",
    authDomain: "webbyreport-811f9.firebaseapp.com",
    databaseURL: "https://webbyreport-811f9.firebaseio.com",
    projectId: "webbyreport-811f9",
    storageBucket: "webbyreport-811f9.appspot.com",
    messagingSenderId: "1029956129168",
    appId: "1:1029956129168:web:b865f4a6d14ef5bf29e315"
  };

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();



export default function Speech({full = false}) {
    const [tweet, setTweet] = useState('');
    let { id } = useParams();
    const [video, setVideo] = useState(null);
    const [wide, setWide] = useState(false);
    
    const getStuff = async () => {
        /*let endpoint = 'https://us-central1-webbyreport-811f9.cloudfunctions.net/getVideoLovies?videoID='+id;

        if(full === true) {
            // get full speech video
            endpoint = Config.endpoint + '/getVideoByPieceLovies?pieceID='+id;
        }
        const v = await axios.get( endpoint);*/


        const v = await db.collection('lovies_videos').doc(id).get()
        if(v.exists) {
            setVideo(v.data());

            let org = v.data().Twitter !== '' && v.data().Twitter ? '@'+v.data().Twitter : v.data().Organization;

            //let presenter = v.data.PresenterTwitter !== '' && v.data.PresenterTwitter ? '@'+v.data.PresenterTwitter : v.data.Presenter;

            let t = `HUGE NEWS! 🙌 ${org} won a #Lovie! Check out my #7WordsOfLovie speech #Lovies`
            if(org === 'Nishma Robb, Google') {
                t = 'Watch a special congratulations message from @NishmaRobb'
            }

            if(org === 'Imogen Heap Presents') {
                t = 'HUGE NEWS! 🙌 Imogen Heap Presents @gorillaz with a Lovie Lifetime Achievement Award! Check out their #7WordsOfLovie speech'
            }
            setTweet(t);
        }
    };

    const FBShare = () => {
        window.open(
            'https://www.facebook.com/sharer.php?u='+encodeURIComponent(Config.rootURL + '/watch/'+id),
            'fb share', 
            'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=600,height=600'
        );
    };

    useEffect(() => {
        getStuff();
        if(id === 'moses' || id === 'monologue' || id ==='closing') {
            setWide(true);
        }
    }, []);

    
    return(
        <>
        <Header />
        <div className={wide ? "speech-page speech-page-wide" : "speech-page"}>       
            {video ? 
                (
                    <div className="speech-page-inner">
                        {full ? 
                            <ShowVideoPlayer pieceID={video.PieceID} hash={video.hash} />
                        :
                            <VideoPlayer videoID={id} poster={Config.bucketURL + video.hash + '.jpg'} src={Config.bucketURL + video.hash + '-share.mp4'} loop={false} />
                        }
                        <div className="speech-info">
                            <h2 className="pink">{video.OrganizationUrl ? <a href={video.OrganizationUrl} target="_blank" rel="noopener noreferrer">{video.Organization}</a> : video.Organization}</h2>
                            {video.PieceTitle ? (<><h1>{video.PieceTitle}</h1></>) : null}
                            {video.awards ? video.awards.map((award, a) => {
                                return <div className="award-single" key={a}>
                                    {award.pv ? <div className="pv">{award.AwardLevel} / People's Lovie Winner</div> : <div className="pv">{award.AwardLevel.toUpperCase()}</div>}
                                    <div className="award-single-sub">{award.MediaType} / {award.CategoryType} / {award.Category}</div>
                                </div>
                            }) : null}
                            {video.GalleryUrl ? <><a href={video.GalleryUrl} target="_blank" rel="noopener noreferrer" className="work-url">View Work</a><br /></> : null}
                            

                            {/*<div className="section-final-message message-text">
                                <p>Here's your 7 Words of Lovie Speech video! All winner speeches including Special Achievement will be available to watch and share at <a href="https://lovieseverywhere.com" className="pink" target="_blank">lovieseverywhere.com</a> starting at 14:00GMT on 19 November. For now, please just make sure you're happy with your speech video, and if you need to make a change, you can upload again.</p>
                                <p className="pink"><strong>But remember:</strong> no sharing on social until the 19th!</p>
                            </div>*/}

                            
                            <div className="side-by-side">
                                <div><a className="twitter-share-button" href={"https://twitter.com/intent/tweet?text="+encodeURIComponent(tweet)+"&via=lovieawards&hashtags=Lovies,7WordsOfLovie&url="+encodeURIComponent(Config.rootURL + '/watch/'+id)}><img src={Twitter} alt="Share to Twitter" /></a></div>
                                <div><a href="#" onClick={()=>FBShare()}><img src={Facebook} alt="Share to Facebook" /></a></div>
                            </div>
                        </div>
                    </div>
                )
            : <Loader text="Loading Video" />}
         </div>
        <Footer />
        </>
    );
}