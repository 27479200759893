import React, { useRef, useEffect } from 'react'
import { Canvas, useFrame  } from 'react-three-fiber'
import * as THREE from 'three'
import {gsap} from 'gsap'

const tempObject = new THREE.Object3D()

function Box({position, reverse = false}) {
  const box = useRef()

  useFrame((state, delta) => {
    if(reverse) {

      box.current.rotation.y -= delta * 0.5
    } else {
      box.current.rotation.x -= delta * 0.5
    }
    
  })

  return (
    <mesh ref={box} position={position}>
      <boxBufferGeometry args={[1,1,1]} />
      <meshStandardMaterial color={'#E50179'} />
    </mesh>
  )
}

function BoxInstance({number = 4*14}) {
  const ref = useRef()

  useFrame((state, delta) => {
    let i = 0

    for( let y = -7 ; y < 7 ; y++) {
      for( let x = -2 ; x < 2 ; x++ ) {
        const id = i++
        tempObject.position.set(x * 1.75, y * 1.75, 0)
        //tempObject.rotation.y -= delta * 0.5
        tempObject.updateMatrix()
        ref.current.setMatrixAt(id, tempObject.matrix)
      }
    }
    ref.current.instanceMatrix.needsUpdate = true
  })
  return (
    <instancedMesh ref={ref} args={[null,null, number]}>
      <boxBufferGeometry args={[1,1,1]} />
      <meshStandardMaterial color={'#E50179'} />
    </instancedMesh>
  )
}

function Grid() {
  const group = useRef()
  const anim = useRef()
  const a = []
  const b = []
  
  for( let y = -7 ; y < 7 ; y++) {
    for( let x = -2 ; x < 2 ; x++ ) {
      a.push(<Box key={'a'+x+y} position={[x * 1.75, y * 1.75, 0]} />)
    }
  }

  for( let y = -7 ; y < 7 ; y++) {
    for( let x = -2 ; x < 2 ; x++ ) {
      if(y !== -2 && y !== -1 && y !== 0 && y !== 1) {
        b.push(<Box key={'b'+x+y} position={[x * 1.75, y * 1.75, 0]} reverse />)
      }
      
    }
  }

  const rotate = () => {
    if(group.current) {
      if(group.current.rotation) {
        anim.current = gsap.to(group.current.rotation, {
          duration: 2,
          y: group.current.rotation.y + Math.PI,
          ease: "back.inOut(1.7)",
          onComplete: () => {
            setTimeout(() => rotate(), 6000)
          }
        })
      }
      
    }
    
  }
  
  useEffect(() => {
    let x = new THREE.Box3().setFromObject( group.current ).getCenter( group.current.position ).multiplyScalar( - 1 );
    rotate()

    return () => {
      if(anim.current) {
        anim.current.kill()
      }
      
    }
  }, [])

  return (
    <group ref={group}>
      <group rotation={[0,0,Math.PI / 4]}>
        {a}
      </group>

      <group rotation={[0,Math.PI, Math.PI / 4]}>
        {b}
      </group>
    </group>
  )
}

export default function ThreeD({message = true}) {
    let zoom = window.innerWidth < 780 ? 30 : 25
    if(!message) {
      zoom = window.innerWidth < 780 ? 20 : 20
    }
    return (
        <div className="three-d">
            <Canvas camera={{position: [0,0, zoom], fov: 75}} colorManagement>
                <pointLight color={'white'} intensity={1} position={[5,0,5]} />
                <Grid />
            </Canvas>

            {message ? <div className="three-d-message"><span>Lovies X Everywhere</span> kicks-off at 14:00 GMT on 19th November.</div> : null}
        </div>
    );
}