import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/firestore";
import BrowseCell from './BrowseCell';
import { Link, useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import Analytics from '../Analytics';

import Config from '../Config';
import Header from '../Header';
import Footer from '../Footer';
import Rivers from './Rivers';
import ThreeD from '../home/ThreeD';

import Loader from '../recorder/Loader'

import tp from '../img/tp.jpg';


const firebaseConfig = {
    apiKey: "AIzaSyBYWeaP4EOl0gVh59sj-d0BgSnOpJhQaHg",
    authDomain: "webbyreport-811f9.firebaseapp.com",
    databaseURL: "https://webbyreport-811f9.firebaseio.com",
    projectId: "webbyreport-811f9",
    storageBucket: "webbyreport-811f9.appspot.com",
    messagingSenderId: "1029956129168",
    appId: "1:1029956129168:web:b865f4a6d14ef5bf29e315"
  };

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

export default function Browse() {
    let { term } = useParams();
    let history = useHistory();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const [searchTerm, setSearchTerm] = useState(term);
    const [playlists, setPlaylists] = useState(null);
    const [results, setResults] = useState([]);
    const [searching, setSearching] = useState(false);

    const handleChange = event => {
        source.cancel();
        setSearchTerm(event.target.value);
        history.push("/search/"+encodeURIComponent(event.target.value));
    };

    const search = async () => {
        setSearching(true);

        let ppp = null
        if(!playlists) {
            ppp = await getRivers()
        }

        if(searchTerm !== "" && searchTerm) {

            /*if(searchTerm.length <= 2) {
                setResults([]);
                setSearching(false);
                return 
            }*/

            try {
                // weird race conditions shit...
                if(playlists) {
                    ppp = playlists
                }

                //const results = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/searchLovies?query='+(term), {
                //    cancelToken: source.token
                //});
                //setResults(results.data);
                const terms = searchTerm.toLowerCase().split(' ')
                const results = []
                for(let x = 0 ; x < ppp.length ; x++) {
                    for(let y = 0 ; y < ppp[x].videos.length ; y++) {
                        let cell = ppp[x].videos[y];
                        for(let k = 0 ; k < cell.keywords.length ; k++) {
                            let found = false;
                            for(let term = 0 ; term < terms.length ; term++) {
                                if(cell.keywords[k].indexOf(terms[term]) >= 0) {
                                    found = true;
                                }
                            }

                            if(found) {
                                results.push(cell) 
                            }
                            
                        }
                    }
                }

                setResults(results);
                setSearching(false);
            } catch(err) {
                console.log(err);
            } 
        } else {
            setResults([]);
            setSearching(false);
        }        
    };

    const getVideosInPlaylistLovies = async(playlist) => {
        return new Promise((resolve, reject) => {
            db.collection('lovies_videos').where('official','==', true).where('playlists', 'array-contains', playlist).get()
                .then(snapshot=> {
                    if(snapshot.empty) {
                        resolve([])
                    } else {
                        const videos = [];
                        snapshot.forEach(async doc => {
                            const obj = doc.data();
                            obj.id = doc.id;
                            videos.push(obj);
                        });
                        resolve(videos);
                    }
                })
                .catch(err => console.log(err));
            });
    }

    const getRivers = async () => {
        //const results = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/getPlaylistsLovies');

        const pl = await db.collection("defaults").doc("lovies_browse").get()
        const playlists = pl.data().playlists

        const final = [];
        for(let x = 0 ; x < playlists.length ; x++) {
            const obj = await getVideosInPlaylistLovies(playlists[x]);
            final.push({name: playlists[x], videos: obj});
        }
        setPlaylists(final);

        return final;
    };

    useEffect(() => {
        setResults([])
        setSearchTerm(term);
    }, [term]);

    useEffect(() => search(), [searchTerm])

    useEffect(() => {
        if(!playlists) {
            let z = getRivers();
        }

        Analytics.sendEvent('Browse Page');
    }, [])

    return(
        <>
            <Header section="browse" />
            <div className="browse">
                <div className="browse-header">
                    <div>
                        <div className="browse-gl"><ThreeD message={false} /></div>
                        <div className="browse-header-bottom">
                            <div className="browse-header-pre">Welcome to the first-ever virtual Lovie Awards Celebration:</div>
                            <h1><span className="pink">Lovies x Everywhere</span></h1>
                            <div className="browse-header-sub">Honouring the Best of the European Internet.<br />Watch and share winners' Official 7 Words of Lovie Acceptance Speeches!</div>
                        </div>
                        
                    </div>
                </div>
                <div className="browse-searchbar">
                    <div>
                        <input type="text" placeholder={window.innerWidth < 700 ? "Search" : "Search All Speeches and Moments"} value={decodeURIComponent(searchTerm || '')} onChange={handleChange} />
                        <Link to="/" className={!searchTerm ? 'clear clear-hide' : 'clear'}>Clear Search</Link>
                    </div>
                </div>

                {!searchTerm || searchTerm === "" ? (
                    <>
                    <div className="browse-grid">
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'welcome.jpg)'}}>
                            <Link to={'/watch/welcome'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">The Lovie Awards</div><br />
                                    <div className="home-video-meta-title">Welcome!</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'gorillaz.jpg)'}}>
                            <Link to={'/watch/gorillaz'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Imogen Heap Presents to</div><br />
                                    <div className="home-video-meta-title">Gorillaz</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'jameela.jpg)'}}>
                            <Link to={'/watch/jameela'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">ALOK Presents to</div><br />
                                    <div className="home-video-meta-title">Jameela Jamil</div>
                                </div>
                            </Link>
                        </div>
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + '61059d98-787a-46b2-8fea-194e34c4bbc9.jpg)'}}>
                            <Link to={'/watch/1RyJjr51CTti0kE7b5MH'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">7 Words of Lovie</div><br />
                                    <div className="home-video-meta-title">Rita Ora</div>
                                </div>
                            </Link>
                        </div>                        
                    </div>
                    <div className="browse-grid">
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'ee454335-8f7f-49ae-adb4-2282c895f74a.jpg)'}}>
                            <Link to={'/watch/SqbvkRJ4pZJFOx20Mn9W'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">7 Words of Lovie</div><br />
                                    <div className="home-video-meta-title">Reni Eddo-Lodge</div>
                                </div>
                            </Link>
                        </div>                      
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'dept.jpg)'}}>
                            <Link to={'/watch/dept'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Lovie Agency of the Year</div><br />
                                    <div className="home-video-meta-title">DEPT</div>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'google.jpg)'}}>
                            <Link to={'/watch/google'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">Congratulations Winners</div><br />
                                    <div className="home-video-meta-title">Google</div>
                                </div>
                            </Link>
                        </div>
                        
                        <div className="browse-grid-cell" style={{backgroundImage: 'url('+Config.bucketURL + 'b09db029-f038-4bfd-9021-17cd9334a9c9.jpg)'}}>
                            <Link to={'/watch/EoOFQVioGyeVnLsmreQ2'}>
                                <div className="play-btn"></div>
                                <div className="home-video-meta">
                                    <div className="home-video-meta-sub">7 Words of Lovie</div><br />
                                    <div className="home-video-meta-title">CIAOPEOPLE</div>
                                </div>
                            </Link>
                        </div>  
                    </div>
                    </>
                ) : null}

                <div className={!searchTerm ? "browse-content browse-river" : "browse-content browse-search"}>

                    {results ? results.map((result, r) => {
                        return <BrowseCell key={r} data={result} />;
                    }) : null}

                    {searching && searchTerm? <Loader text="Searching..." /> : (
                        results.length === 0 && searchTerm ? (
                            <div className="browse-nothing">
                                <div>No Results Found</div>
                            </div>) : null
                    )}

                    {!searchTerm || searchTerm === "" ? (
                        playlists ? <Rivers playlists={playlists} />  : <Loader text="Loading playlists..." />
                        
                    ) : null } 
                        
                </div>
                
            </div>
            <Footer />
            
        </>
    );
}