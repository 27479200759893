import React from 'react';
import logo from './img/lovies-logo.png';

import Twitter from './img/twitter.svg';
import Facebook from './img/facebook.svg';
import Instagram from './img/instagram.svg';
import Youtube from './img/youtube.svg';

import google from './img/google.svg';

export default function Footer() {
    return (
        <>

            <div className="footer-pre footer-pre-page">
                <div>
                    PRESENTED BY:
                    <a href="https://google.com" target="_blank" rel="noopener noreferrer"><img src={google} alt="Google" /></a>
                </div>
            </div>
            <footer>
                <div className="footer-inner">
                    <div className="footer-left">
                        <a href="https://www.lovieawards.eu/" target="_blank" rel="noopener noreferrer"><img src={logo} alt="The Webby Awards" /></a>
                        <div className="copyright">&copy; 2020 The Lovie Awards. All rights reserved.</div>
                    </div>
                    <div className="footer-right">
                        <div className="footer-social">
                            <ul>
                            <li><a href="http://www.twitter.com/lovieawards" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="Twitter" /></a></li>
                            <li><a href="https://www.facebook.com/thelovieawards" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a></li>
                            <li><a href="http://instagram.com/thelovieawards" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a></li>
                            <li><a href="http://www.youtube.com/user/lovieawards" target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="YouTube" /></a></li>
                            </ul>
                        </div>
                        <ul>
                            <li><a href="https://www.lovieawards.eu/contact-us/" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                            <li><a href="http://www.iadas.net/" target="_blank" rel="noopener noreferrer">IADAS</a></li>
                            <li><a href="https://www.lovieawards.eu/enter/faq/" target="_blank" rel="noopener noreferrer">FAQ</a></li>
                            <li><a href="https://www.lovieawards.eu/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
}